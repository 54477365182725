.pageViewer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page {
    height: 85%;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .pageViewer {
        flex-direction: column-reverse;
    }
}