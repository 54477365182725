.nav {
    background-color: #00ACDF;
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10%;
    font-size: 110%;
}

.nav h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #ffffff;
}

.nav h3:hover {
    color: #000000;
}

.nav a {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #ffffff;
    text-decoration: none;
}

.nav a:hover {
    color: #000000;
}

@media only screen and (max-width: 600px) {
    .nav {
        width: 100%;
    }
}