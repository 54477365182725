.skills {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
}

.skill_header {
    font-size: 5vh;
    font-family: 'Playfair Display', serif;
    margin-bottom: 2%;
    margin-top: 2%;
}

div#other.skill_header {
    font-size: 3vh;
    margin-bottom: 0%;
}

.skill_title {
    font-size: 4vh;
    margin-top: 10px;
    color: #00ACDF;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-align: center;
}

.skill_other {
    font-size: 2vh;
    margin-top: 10px;
    color: #00ACDF;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    text-align: center;
}

@media only screen and (max-width: 600px) {

    .skill_header {
        font-size: 4vh;
        margin-bottom: 8%;
    }

    .skill_extra {
        margin-left: 10%;
        margin-right: 10%;
    }

    div#other.skill_header {
        margin-top: 5%;
        margin-bottom: 0%;
    }
}