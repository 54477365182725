.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15%;
}

.titleWrapper h1 {
    font-size: 7vh;
    margin-bottom: 0px;
    font-family: 'Playfair Display', serif;
}

.titleWrapper h2 {
    font-size: 4vh;
    margin-top: 10px;
    color: #00ACDF;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.home p {
    font-size: 3vh;
    width: 75%;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}